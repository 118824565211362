<template>
  <div class="product-showcase">
    <div class="product-showcase__w">
      <slot />
      <ProductShowcaseTabs
        :tabs="tabs"
        :current-tab="currentTab"
        :with-scroll="withScroll"
        @change-tab="onChangeTab"
      />
      <KeepAlive :max="6">
        <Suspense>
          <Component
            :is="tabs[currentTab].component"
            :category="tabs[currentTab].category"
            :label="tabs[currentTab].label"
          />
          <template #fallback>
            <span class="ui-loader" />
          </template>
        </Suspense>
      </KeepAlive>
    </div>
  </div>
</template>

<script setup>
import ProductShowcaseTabs from "~/modules/shared/productShowcase/ProductShowcaseTabs.vue";

//https://vuejs.org/guide/built-ins/suspense.html Experimental!

const props = defineProps({
  tabs: { type: Array, required: true },
  withScroll: { type: Boolean, required: false, default: false },
});

const currentTab = ref(0);

const onChangeTab = (tab) => {
  currentTab.value = tab;
};
</script>

<style lang="scss" scoped>
.product-showcase {
  min-height: 410px;

  @include flex-container(row, center);

  padding-inline: 16px;

  &__w {
    @extend %width-main;

    @include flex-container(column, flex-start);
    gap: 32px;

    @include bigMobile {
      gap: 16px;
    }
  }
}
</style>
